import { HTMLAttributeAnchorTarget } from 'react';

import { FormattedPriceText } from '@/components/FormattedPriceText';
import { Link } from '@/components/Link';
import ChevronIcon from '@/components/icons/ChevronIcon';
import { Frequency } from '@/models/Frequency.enum';

type DayPassCtaPriceProps = {
  href: string;
  price: number;
  label: string;
  currencyIsoCode: string;
  frequency: Frequency;
  dataTestId: string;
  target?: HTMLAttributeAnchorTarget;
};

export function DayPassCtaPrice({
  href,
  price,
  label,
  currencyIsoCode,
  frequency,
  dataTestId,
  target,
}: DayPassCtaPriceProps) {
  return (
    <Link
      href={href}
      target={target}
      className="flex-wrap justify-center border-2 rounded-full border-[#013E3F]/10 py-[6px] sm:py-2 pl-2 pr-1 sm:pl-4 sm:pr-3 flex hover:bg-[#013E3F14] text-ocean-60"
      data-testid={dataTestId}
    >
      <strong className="mr-2 text-sm">{label}</strong>
      <span className="flex items-center">
        <FormattedPriceText
          price={price}
          currencyIsoCode={currencyIsoCode!}
          frequency={frequency}
          maximumFractionDigits={0}
        />
        <ChevronIcon className="ml-1 scale-110 -rotate-90 stroke-ocean-60" />
      </span>
    </Link>
  );
}
