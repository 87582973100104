import { useTranslations } from 'next-intl';

import { ImageProps } from '@/components/modules/types';
import { SimpleLocationDto } from '@/lib/locations/dto';

export function useLocationImageOrPlaceholder<
  T extends SimpleLocationDto['image']
>(image?: T): ImageProps {
  const t = useTranslations('general');

  if (image == null) {
    return {
      src: `/public-s3/images/location-placeholders/placeholder_1.webp`,
      alt: t('image-placeholders.1.alt'),
    };
  }

  return {
    src: image.url,
    alt: image.altText ?? t('alt-default-image'),
    caption: image.caption ?? undefined,
  };
}
