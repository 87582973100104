import { BreadcrumbList, WithContext } from 'schema-dts';

type BreadcrumbListSchemaProps = {
  schema: WithContext<BreadcrumbList>;
};

export function BreadcrumbListSchema(props: BreadcrumbListSchemaProps) {
  const { schema } = props;

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
}
