import { tiemposFont } from '@/theme/tiemposFont';

import clsx from 'clsx';

type MarketDescriptionTitleProps = {
  text: string;
};

export default function MarketDescriptionTitle({
  text,
}: MarketDescriptionTitleProps) {
  return (
    <h2
      className={clsx(tiemposFont.className, 'font-medium text-[1.5rem] mb-4')}
    >
      {text}
    </h2>
  );
}
