import { useTranslations } from 'next-intl';

import { LatLng } from '@/models';
import {
  getOpeningDate,
  getOpeningLabelTranslationKey,
} from '@/utils/locations';

import clsx from 'clsx';

type LocationOpeningLabelProps = {
  openingDateISO: string | null;
  locationGeolocalization: LatLng;
  className?: string;
};

export default function LocationOpeningLabel(props: LocationOpeningLabelProps) {
  const { openingDateISO, locationGeolocalization, className } = props;
  const t = useTranslations('location');

  if (!openingDateISO) return null;
  const date = getOpeningDate(openingDateISO);

  const translationKey = getOpeningLabelTranslationKey({
    openingDate: date,
    latitude: locationGeolocalization.lat,
  });

  return (
    <div className={clsx('italic font-bold capitalize', className)}>
      {t(`opening-${translationKey}`, { date, year: date.getFullYear() })}
    </div>
  );
}
