import { useState } from 'react';

import { MIN_TOUCH_SCROLL_SIZE } from './constants';

type useScrollHandlersProps = {
  canGoNext: boolean;
  canGoPrev: boolean;
  goNext: () => void;
  goPrev: () => void;
};

export function useScrollHandlers(props: useScrollHandlersProps) {
  const { canGoNext, canGoPrev, goNext, goPrev } = props;
  const [touchPosition, setTouchPosition] = useState<number | null>(null);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > MIN_TOUCH_SCROLL_SIZE && canGoNext) {
      goNext();
    }

    if (diff < -MIN_TOUCH_SCROLL_SIZE && canGoPrev) {
      goPrev();
    }

    setTouchPosition(null);
  };

  return { handleTouchStart, handleTouchMove };
}
