import { getHomeListItemSchema } from './list-items/home-list-item';
import { getMetroListItem } from './list-items/metro-list-item';

import { BreadcrumbList, WithContext } from 'schema-dts';

export function getMetroBreadcrumbList(props: {
  name: string;
  slug: string;
  host: string;
}): WithContext<BreadcrumbList> {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [getHomeListItemSchema(), getMetroListItem(props)],
  };
}
