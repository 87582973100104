'use client';

import { useLocale, useTranslations } from 'next-intl';

import { Frequency } from '@/models/Frequency.enum';

type FormattedPriceTextProps = {
  price: number;
  currencyIsoCode: string;
  frequency?: Frequency;
  maximumFractionDigits?: number;
} & React.ComponentPropsWithoutRef<'div'>;

export function FormattedPriceText({
  currencyIsoCode,
  price,
  frequency,
  maximumFractionDigits,
  ...divProps
}: FormattedPriceTextProps) {
  const locale = useLocale();
  const t = useTranslations('general');

  const formattedPrice = Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyIsoCode,
    maximumFractionDigits,
  }).format(price);

  if (frequency == null) {
    return <div {...divProps}>{formattedPrice}</div>;
  }

  const formattedPriceElement = t.rich(getFrequencyTranslationKey(frequency), {
    price: formattedPrice,
    frequency: (chunks) => <span className="font-normal">{chunks}</span>,
  });

  return <div {...divProps}>{formattedPriceElement}</div>;
}

function getFrequencyTranslationKey(frequency: Frequency) {
  switch (frequency) {
    case Frequency.Daily:
      return 'daily-price';
    case Frequency.Hourly:
      return 'hourly-price';
    case Frequency.Monthly:
      return 'monthly-price';
  }
}
