import { ListItem } from 'schema-dts';

export function getHomeListItemSchema(): ListItem {
  return {
    '@type': 'ListItem',
    position: 1,
    name: 'Home',
    item: 'https://www.industriousoffice.com/',
  };
}
