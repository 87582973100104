import { RefObject, useEffect } from 'react';

export const APPEAR_ON_SCROLL_CLASS = 'appear-on-scroll';

function useAppearOnScroll<T extends HTMLElement = HTMLElement>(
  observableRef: RefObject<T>
) {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(APPEAR_ON_SCROLL_CLASS);
          } else {
            entry.target.classList.remove(APPEAR_ON_SCROLL_CLASS);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    if (observableRef.current) {
      observer.observe(observableRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [observableRef]);
}

export default useAppearOnScroll;
