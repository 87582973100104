import { PrimaryHeading } from '../typography';

import clsx from 'clsx';

type SearchTitleProps = {
  text: string;
  className?: string;
};

export default function SearchTitle({ text, className }: SearchTitleProps) {
  return (
    <PrimaryHeading className={clsx('!text-2xl lg:!text-4xl', className)}>
      {text}
    </PrimaryHeading>
  );
}
