import clsx from 'clsx';

export default function SearchCard({
  className,
  ...otherProps
}: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      className={clsx(
        'flex flex-col h-full bg-white rounded-lg group',
        className
      )}
      {...otherProps}
    />
  );
}
