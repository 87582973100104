import { ListItem } from 'schema-dts';

export function getMetroListItem(props: {
  name: string;
  slug: string;
  host: string;
}): ListItem {
  const { name, slug, host } = props;
  return {
    '@type': 'ListItem',
    position: 2,
    name,
    item: `${host}/m/${slug}`,
  };
}
