import { ReactNode, useEffect, useState } from 'react';

import { useScrollHandlers } from '../Carousel/useScrollHandlers';

export function TilesCarousel({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [widthTile, setWidthTile] = useState(0);
  const canGoNext = Array.isArray(children)
    ? activeIndex < children.length - 1
    : false;
  const canGoPrev = activeIndex > 0;

  const handlePrevButtonClicked = () => {
    setActiveIndex((prevState) => prevState - 1);
  };

  const handleNextButtonClicked = () => {
    setActiveIndex((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (window?.innerWidth) {
      setWidthTile(window.innerWidth - 40);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    setActiveIndex(0);
  }, [children]);

  const { handleTouchMove, handleTouchStart } = useScrollHandlers({
    canGoNext,
    canGoPrev,
    goNext: handleNextButtonClicked,
    goPrev: handlePrevButtonClicked,
  });

  return (
    <div
      className="fixed bottom-20 ml-[20px]"
      style={{
        width: widthTile * (Array.isArray(children) ? children.length : 1),
      }}
    >
      <div
        className="flex gap-2 transition-transform duration-500 whitespace-nowrap h-[100px] sm:h-[150px] md:h-[180px]"
        style={{
          transform: `translateX(-${activeIndex * widthTile}px)`,
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        {children}
      </div>
    </div>
  );
}

export function TilesCarouselItem({ children }: { children: ReactNode }) {
  return (
    <div className="bg-white rounded-lg shadow w-[calc(100vw-40px)]">
      {children}
    </div>
  );
}
